import classNames from "classnames";
import React, { forwardRef, KeyboardEvent } from "react";

import Icon from "../Icon";

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  checked?: boolean;
  label: React.ReactNode;
  className?: string;
  onChange?: (event: KeyboardEvent<HTMLInputElement>) => void;
};

type Ref = HTMLInputElement;

const Checkbox = forwardRef<Ref, Props>(
  ({ checked = false, label, className, ...props }, ref) => (
    <div className={classNames("checkbox", className)}>
      <label className="flex items-center cursor-pointer">
        <input
          className="sr-only"
          type="checkbox"
          ref={ref}
          {...props}
          onKeyPress={(e) => props.onChange?.(e)}
        />
        <div
          className={classNames(
            "w-4 h-4 border rounded mr-2 relative text-xs",
            {
              "border-blue-600 text-blue-600": checked,
            }
          )}
        >
          {checked && (
            <Icon
              className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
              as="fa-check"
            />
          )}
        </div>
        <span className="text-gray-700">{label}</span>
      </label>
    </div>
  )
);

export default Checkbox;
