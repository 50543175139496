import classNames from "classnames";
import React, { ReactNode } from "react";

type Props = {
  className?: string;
  children?: ReactNode;
};

export default function Container({ className, ...props }: Props) {
  return (
    <div
      className={classNames("container mx-auto max-w-sm p-2", className)}
      {...props}
    />
  );
}
