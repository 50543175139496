import classNames from "classnames";
import React, { forwardRef, InputHTMLAttributes } from "react";

type Props = InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
  hasError?: boolean;
};

type Ref = HTMLInputElement;

export default forwardRef<Ref, Props>(
  ({ className, hasError = false, ...props }: Props, ref) => (
    <input
      className={classNames(
        "text-gray-700 rounded w-full px-4 h-10 text-sm border-2 outline-none focus:border-blue-700",
        className,
        {
          "border-red-600": hasError,
          "border-gray-300": !hasError,
        }
      )}
      type="text"
      ref={ref}
      {...props}
    />
  )
);
