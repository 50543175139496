import classNames from "classnames";
import React, { ButtonHTMLAttributes } from "react";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & {
  theme?:
    | "primary"
    | "link"
    | "link--danger"
    | "link--muted"
    | "reset"
    | "secondary";
};

const themeMap = {
  reset: "",
  link: "text-blue-600 hover:underline",
  "link--danger": "text-red-600 hover:underline",
  "link--muted": "text-gray-500 hover:underline",
  primary:
    "text-white bg-blue-600 hover:bg-blue-700 rounded h-10 px-3 transition-colors",
  secondary:
    "rounded h-10 px-3 border border-gray-400 text-gray-400  hover:text-gray-600 hover:border-gray-600",
};

export default function Button({
  className,
  theme = "primary",
  type = "button",
  ...props
}: Props) {
  return (
    <button
      className={classNames(themeMap[theme], className)}
      type={type}
      {...props}
    />
  );
}
