import classNames from "classnames";
import React from "react";

type Props = {
  as: string;
  className?: string;
  spin?: boolean;
};

export default function Icon({ as, className, spin = false }: Props) {
  return (
    <i
      aria-hidden="true"
      className={classNames("fa", as, spin && "fa-spin", className)}
    />
  );
}
