import classNames from "classnames";
import React, { ReactNode } from "react";

type Props = {
  className?: string;
  children?: ReactNode;
};

type BodyProps = Props & {
  borderTop?: boolean;
};

const Card = ({ className, ...props }: Props) => {
  return (
    <div
      className={classNames("rounded shadow-md bg-white", className)}
      {...props}
    />
  );
};

Card.Body = ({ className, borderTop = false, ...props }: BodyProps) => {
  return (
    <div
      className={classNames("p-4", borderTop && "border-t", className)}
      {...props}
    />
  );
};

Card.Header = ({ className, ...props }: Props) => {
  return <div className={classNames("py-2 px-4", className)} {...props} />;
};

export default Card;
